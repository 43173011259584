import { gql } from '@apollo/client';
import { ProductLineData } from './product-line';

export const FIND_PAGINATION_PRODUCT_LINE = gql`
  query ProductLines_findPagination($input: BaseQueryPayload) {
    ProductLines_findPagination(input: $input) {
      data {
        ${ProductLineData}
      }
      count
      pages
      limit
      page
    }
  }
`;

export const FIND_ONE_PRODUCT_LINE = gql`
  query ProductLines_findOne($id: String!) {
    ProductLines_findOne(id: $id) {
      ${ProductLineData}
    }
  }
`;

export const CREATE_PRODUCT_LINE = gql`
  mutation ProductLines_create($input: CreateProductLinesInput!) {
    ProductLines_create(input: $input) {
      ${ProductLineData}
    }
  }
`;

export const UPDATE_PRODUCT_LINE = gql`
  mutation ProductLines_update($input: UpdateProductLinesInput!) {
    ProductLines_update(input: $input) {
      ${ProductLineData}
    }
  }
`;
