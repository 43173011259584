import { FindManyProductCategoryPayload } from '@/domains/product-category/ports/payloads';
import { useRequest } from 'ahooks';
import { FindProductCategoriesUseCase } from '@/domains/product-category/usecases';
import ProductCategoriesRepoImpl from '../adapters/repositories/product-category.repo';

export function useFindManyProductCategoriesViewModel() {
  const usecase = new FindProductCategoriesUseCase(new ProductCategoriesRepoImpl());

  const req = useRequest(
    async (payload: FindManyProductCategoryPayload) => {
      try {
        const result = await usecase.findMany(payload);
        return result;
      } catch (error) {
        console.log('error:', error);
        return [];
      }
    },
    { manual: true, retryCount: 2 },
  );
  return req;
}
