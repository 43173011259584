import CoreTable from '@/components/Table';
import { Pagination, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { get, omit } from 'lodash';
import React from 'react';
import { ProductLineColumns } from '../../components/Table/TableColumns';
import { useProductLineContext } from '../../context';
import { ProductLineEntity } from '@/domains/product-line/entities';

export const ProductLinesTable: React.FC = () => {
  const { productLinesFindPaginationAction, methodFilterProductLines } = useProductLineContext();
  const pageData = omit(productLinesFindPaginationAction.data, ['data']);
  const { setValue } = methodFilterProductLines;
  const tableColumns = ProductLineColumns();
  const handleTableChange: TableProps<ProductLineEntity>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
  ) => {
    const statusFilter = get(filters, 'isActive') as boolean[] | null;
    if (statusFilter && statusFilter.length === 1) setValue('filter.isActive', statusFilter[0]);
    else setValue('filter.isActive', undefined);
  };

  return (
    <div className='ml-5 mr-5'>
      <CoreTable
        columns={tableColumns}
        dataSource={productLinesFindPaginationAction.data?.data ?? []}
        tableName='Product Line'
        onChange={handleTableChange}
        loading={productLinesFindPaginationAction.loading}
      />
      <div className='flex mt-4 justify-end'>
        <Pagination
          total={get(pageData, 'count') ?? 0}
          defaultPageSize={get(pageData, 'limit') ?? 20}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          current={get(pageData, 'page') ?? 1}
          onChange={(page, pageSize) => {
            setValue('pagination.page', page);
            setValue('pagination.limit', pageSize);
          }}
        />
      </div>
    </div>
  );
};
