export const ProductLineData = `
  id
  name
  isActive
  createdBy
  createdAt
  lastModifiedBy
  lastModifiedAt
  deletedBy
  deletedAt
  isDeleted
  productCategoryId
  productTopicIds
  
`;
