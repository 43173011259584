import { Button, Modal, notification } from 'antd';
import { Typography } from 'antd/lib';
import React, { useEffect, useId } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from '@/yupGlobal';
import CoreModal from '@/components/CoreModal';
import { useProductLineContext } from '../../context';
import ProductLineForm from './ProductLineForm';
import { useForm } from 'react-hook-form';
import { useUpdateProductLinesViewModel } from '../../viewmodels/update';
import { useFindOneProductLineViewModel } from '../../viewmodels';
import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

type TProductLineEditProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  itemId?: string;
};

const Schema = Yup.object({
  isActive: Yup.boolean().default(true),
  name: Yup.string().required(i18next.t('common.required_field')),
  productCategoryId: Yup.string().required(i18next.t('common.required_field')),
  productTopicIds: Yup.array(Yup.string().required())
    .min(1, i18next.t('common.required_field'))
    .default([]),
});

type TSchema = Yup.InferType<typeof Schema>;

const ProductLineDetail = ({ open, onClose, itemId }: TProductLineEditProps) => {
  const uid = useId();
  const { t } = useTranslation();
  const { productLinesFindPaginationAction } = useProductLineContext();

  const methodForm = useForm<TSchema>({
    mode: 'onChange',
    resolver: yupResolver(Schema),
  });

  const FindOneContractReminderReq = useFindOneProductLineViewModel();
  const UpdateProductLineReq = useUpdateProductLinesViewModel();

  const handleSubmitForm = methodForm.handleSubmit(async (formData) => {
    try {
      if (!itemId) throw new Error('Missing product line id');

      const { isActive, name, productCategoryId, productTopicIds } = formData;
      const res = await UpdateProductLineReq.runAsync({
        id: itemId,
        isActive,
        name,
        productCategoryId,
        productTopicIds,
      });

      if (!res?.id) throw new Error('Failed to Update product line');
      productLinesFindPaginationAction.refresh();

      onClose?.();
      methodForm.reset();
      notification.success({ message: 'Update new product line' });
    } catch (error) {
      console.log('error:', error);
      notification.error({ message: 'Something went wrong' });
    }
  });

  useEffect(() => {
    if (itemId) {
      FindOneContractReminderReq.runAsync({ id: itemId })
        .then((res) => {
          if (res?.id) {
            methodForm.reset(res);
          }
        })
        .catch((err) => {
          console.log(err);
          notification.error({ message: getErrorMessage(err), placement: 'bottomRight' });
          onClose?.();
        });
    }
  }, [itemId]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={
        <Typography.Text type='secondary' style={{ margin: '0 0 0 0px' }}>
          {t('common.productLines')}
        </Typography.Text>
      }
      bodyStyle={{ padding: '0 0' }}
      width={648}
      okButtonProps={{ form: 'ProductLineUpdate', htmlType: 'submit' }}
      cancelButtonProps={{ onClick: onClose }}
    >
      <ProductLineForm
        methodForm={methodForm}
        id={'ProductLineUpdate'}
        onSubmit={handleSubmitForm}
      />
    </Modal>
  );
};

export default ProductLineDetail;
