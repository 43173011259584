import { useRequest } from 'ahooks';
import ProductLineRepoImplement from '../adapters/repositories/product-lines.repo';
import { CreateProductLineUseCase } from '@/domains/product-line/usecases';
import { CreateProductLinePayload } from '@/domains/product-line/ports/payloads';

export function useCreateProductLinesViewModel(options?: any) {
  const usecase = new CreateProductLineUseCase(new ProductLineRepoImplement());

  const req = useRequest(
    async (payload: CreateProductLinePayload) => {
      const result = await usecase.create(payload);
      return result;
    },
    { manual: true, ...(options || {}) },
  );
  return req;
}
