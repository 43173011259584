import { useRequest } from 'ahooks';
import ProductLineRepoImplement from '../adapters/repositories/product-lines.repo';
import { UpdateProductLineUseCase } from '@/domains/product-line/usecases';
import { UpdateProductLinePayload } from '@/domains/product-line/ports/payloads';

export function useUpdateProductLinesViewModel(options?: any) {
  const usecase = new UpdateProductLineUseCase(new ProductLineRepoImplement());

  const req = useRequest(
    async (payload: UpdateProductLinePayload) => {
      const result = await usecase.update(payload);
      return result;
    },
    { manual: true, ...(options || {}) },
  );
  return req;
}
