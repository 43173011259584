import { CreateProductLinePayload } from '../ports/payloads';
import { ProductLinesRepository } from '../ports/repositories';

export class CreateProductLineUseCase {
  constructor(private readonly repo: ProductLinesRepository) {}

  async create(payload: CreateProductLinePayload) {
    return await this.repo.create(payload);
  }
}
