// Generate code from clean architecture template

import {
  FindManyProductCategoryPayload,
  FindPaginationProductCategoryPayload,
} from '../ports/payloads';
import { ProductCategoriesRepository } from '../ports/repositories';

export class FindProductCategoriesUseCase {
  constructor(private readonly repo: ProductCategoriesRepository) {}

  async findPagination(payload?: FindPaginationProductCategoryPayload) {
    return await this.repo.findPagination(payload);
  }

  async findMany(payload?: FindManyProductCategoryPayload) {
    return await this.repo.findMany(payload);
  }
}
