/* eslint-disable @typescript-eslint/no-explicit-any */
import { CoreInput } from '@/components/Form/InputAntForm';
import CoreSelect from '@/components/Form/SelectAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import React, { useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useProductLineContext } from '../../context';
import { ProductTopic } from '@/domains/product-topic/entities';
import { useFindManyProductTopicsViewModel } from '@/modules/product-topic/viewmodels';
import { useTranslation } from 'react-i18next';
import Label from '@/components/Form/Label';
import { ProductEntity } from '@/domains/products/entities';
import useFindAllProducts from '@/modules/products/viewmodels/list';

type TProductLineProps = {
  methodForm: UseFormReturn<any>;
  id: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  hiddenStatus?: boolean;
};

const ProductLineForm = ({
  methodForm,
  id,
  onSubmit,
  className,
  hiddenStatus = false,
}: TProductLineProps) => {
  const { t } = useTranslation();

  const { productCategoriesFindAllAction, productTopicsFindAllAction, productsFindAllAction } =
    useProductLineContext();
  const { data: productCategories } = productCategoriesFindAllAction;

  useEffect(() => {
    productTopicsFindAllAction.runAsync({});
  }, []);

  const [productsFiltered, setProductsFiltered] = React.useState<ProductEntity[]>(
    productsFindAllAction.data?.data || [],
  );

  const FindManyProductReq = useFindAllProducts();

  useEffect(() => {
    if (methodForm.watch('productCategoryId')) {
      const productCategoryId = methodForm.watch('productCategoryId');

      FindManyProductReq.runAsync({ category: productCategoryId }).then((res) => {
        if (res) {
          setProductsFiltered(res.data);
        } else {
          setProductsFiltered([]);
        }
      });
    }
  }, [methodForm.watch('productCategoryId')]);

  return (
    <FormProvider {...methodForm}>
      <form id={id} onSubmit={onSubmit} className={`flex flex-col ${className}`}>
        <div className='grid grid-cols-2 gap-4 mb-4'>
          <CoreInput
            control={methodForm.control}
            name='name'
            label={t('common.productLinesName')}
          />

          <CoreSelect
            control={methodForm.control}
            name='productCategoryId'
            label={t('common.productLinesProductCategory')}
            options={productCategories?.map((item: any) => ({ label: item.name, value: item.id }))}
            optionsProps={[]}
          />
          <CoreSelect
            control={methodForm.control}
            name='productTopicIds'
            label={t('common.productLinesProductTopics')}
            mode='multiple'
            options={productTopicsFindAllAction.data?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            optionsProps={[]}
          />


          {!hiddenStatus && (
            <div className=''>
              <Label label={t('common.productLinesStatus')} />
              <CoreSwitch control={methodForm.control} name='isActive' />
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default ProductLineForm;
