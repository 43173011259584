import React from 'react';
import { ProductLinesTable } from './List/ProductLinesTable';
import { ProductLinesProvider, useProductLineContext } from '../context';
import { Button } from '@/components';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import ProductLineCreate from '../components/Form/ProductLineCreateForm';
import ProductLineDetail from '../components/Form/ProductLineUpdateForm';
import { useTranslation } from 'react-i18next';

const InternalProductLinesScreen: React.FC = () => {
  const { t } = useTranslation();

  const { productLinesFindPaginationAction, pageUrlState, setPageUrlState } =
    useProductLineContext();

  const handleCloseModal = () => {
    setPageUrlState({
      id: undefined,
      action: undefined,
    });
  };

  return (
    <>
      <div className='bg-white py-10 flex flex-col flex-wrap'>
        <div>
          <div className='flex justify-between items-center px-4 pb-4 gap-4'>
            <div className='flex items-center'>
              <span className='text-base font-[600] opacity-60'>{t('common.productLines')}</span>
            </div>

            <div className='flex items-center gap-4'>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => {
                  productLinesFindPaginationAction.refresh();
                }}
              />
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setPageUrlState((p) => ({ ...p, action: 'Create', id: undefined }));
                }}
              >
                {t('common.create')}
              </Button>
            </div>
          </div>
          <ProductLinesTable />
        </div>
      </div>

      <ProductLineCreate open={pageUrlState?.action === 'Create'} onClose={handleCloseModal} />
      <ProductLineDetail
        open={pageUrlState?.action === 'Detail'}
        onClose={handleCloseModal}
        itemId={pageUrlState.id}
      />
    </>
  );
};

export default function ProductLinesScreen() {
  return (
    <ProductLinesProvider>
      <InternalProductLinesScreen />
    </ProductLinesProvider>
  );
}
