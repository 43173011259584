// Generate code from clean architecture template

import { FindAllProductsResponse, QueryProductsPayload } from '../ports/payloads';
import { ProductsRepository } from '../ports/repositories';

export class FindAllProductsUseCase {
  constructor(private readonly repo: ProductsRepository) {}

  async run(payload?: QueryProductsPayload): Promise<FindAllProductsResponse> {
    return this.repo.findPaginate(payload);
  }
}
