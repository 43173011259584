import { useRequest } from 'ahooks';
import { FindProductTopicsUseCase } from '@/domains/product-topic/usecases';
import ProductTopicsRepoImplement from '../adapters/repositories/product-topic.repo';
import { FindManyProductTopicPayload } from '@/domains/product-topic/ports/payloads';

export function useFindManyProductTopicsViewModel() {
  const usecase = new FindProductTopicsUseCase(new ProductTopicsRepoImplement());

  const req = useRequest(
    async (payload: FindManyProductTopicPayload) => {
      try {
        const result = await usecase.findMany(payload);
        return result;
      } catch (error) {
        console.log('error:', error);
        return [];
      }
    },
    { manual: true, retryCount: 2 },
  );
  return req;
}
