import { ProductLineEntity } from '@/domains/product-line/entities';
import {
  FindPaginationProductLinePayload,
  CreateProductLinePayload,
  UpdateProductLinePayload,
  FindOneProductLinePayload,
} from '@/domains/product-line/ports/payloads';
import { ProductLinesRepository } from '@/domains/product-line/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CREATE_PRODUCT_LINE,
  FIND_ONE_PRODUCT_LINE,
  FIND_PAGINATION_PRODUCT_LINE,
  UPDATE_PRODUCT_LINE,
} from '../../graphql';
import { FindResult } from '@/core/interfaces/findResult';

export default class ProductLineRepoImplement implements ProductLinesRepository {
  async findOne(payload: FindOneProductLinePayload): Promise<ProductLineEntity> {
    const result = await HttpClientAdapter.query<ProductLineEntity>({
      query: FIND_ONE_PRODUCT_LINE,
      dataKey: 'ProductLines_findOne',
      variables: { id: payload.id },
    });
    return result;
  }
  async findPagination(
    payload: FindPaginationProductLinePayload,
  ): Promise<FindResult<ProductLineEntity>> {
    const result = await HttpClientAdapter.query<FindResult<ProductLineEntity>>({
      query: FIND_PAGINATION_PRODUCT_LINE,
      dataKey: 'ProductLines_findPagination',
      variables: { input: { ...payload } },
    });
    return result;
  }
  async create(payload: CreateProductLinePayload): Promise<ProductLineEntity> {
    const result = await HttpClientAdapter.mutate<ProductLineEntity>({
      mutation: CREATE_PRODUCT_LINE,
      dataKey: 'ProductLines_create',
      variables: { input: { ...payload } },
    });
    return result;
  }
  async update(id: string, payload: UpdateProductLinePayload): Promise<ProductLineEntity> {
    const result = await HttpClientAdapter.mutate<ProductLineEntity>({
      mutation: UPDATE_PRODUCT_LINE,
      dataKey: 'ProductLines_update',
      variables: { input: { ...payload, id } },
    });
    return result;
  }
}
