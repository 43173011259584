/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect } from 'react';
import { ProductLineEntity } from '@/domains/product-line/entities';
import { FindPaginationProductLinePayload } from '@/domains/product-line/ports/payloads';
import { ProductCategory } from '@/domains/product-category/entities';
import { FindManyProductCategoryPayload } from '@/domains/product-category/ports/payloads';
import { ProductTopic } from '@/domains/product-topic/entities';
import { FindManyProductTopicPayload } from '@/domains/product-topic/ports/payloads';
import { useFindPaginationProductLinesViewModel } from '../viewmodels';
import { pick } from 'lodash';
import { useFindManyProductCategoriesViewModel } from '@/modules/product-category/viewmodels';
import { useFindManyProductTopicsViewModel } from '@/modules/product-topic/viewmodels';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useUrlState } from '@/core/hooks/useUrlState';
import { FindResult, UseRequestReturn } from '@/core/interfaces/findResult';
import useFindAllProducts from '@/modules/products/viewmodels/list';
import { FindAllProductsResponse, QueryProductsPayload } from '@/domains/products/ports/payloads';

interface TPageUrlState {
  id?: string;
  action?: string;
  tab?: string;
}
interface TContextValues {
  productLinesFindPaginationAction: Pick<
    UseRequestReturn<FindResult<ProductLineEntity> | undefined, [FindPaginationProductLinePayload]>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  productCategoriesFindAllAction: Pick<
    UseRequestReturn<ProductCategory[], [FindManyProductCategoryPayload]>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  productTopicsFindAllAction: Pick<
    UseRequestReturn<ProductTopic[], [FindManyProductTopicPayload]>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  productsFindAllAction: Pick<
    UseRequestReturn<FindAllProductsResponse, [QueryProductsPayload]>,
    'data' | 'loading' | 'runAsync' | 'refresh'
  >;
  methodFilterProductLines: UseFormReturn<FindPaginationProductLinePayload>;

  pageUrlState: TPageUrlState;
  setPageUrlState: React.Dispatch<React.SetStateAction<TPageUrlState>>;
  resetPage: () => void;
}
const ProductLineContext = React.createContext<TContextValues>({} as any);

export const useProductLineContext = () => React.useContext(ProductLineContext);

const Provider = ({ children, ...props }: React.PropsWithChildren & TContextValues) => (
  <ProductLineContext.Provider value={props}>{children}</ProductLineContext.Provider>
);

const MemoProvider = memo(Provider);

// main context provider

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProductLinesProvider: React.FC<any> = ({ children }: React.PropsWithChildren) => {
  const FindPaginationProductLinesReq = useFindPaginationProductLinesViewModel();
  const FindManyProductCategoriesReq = useFindManyProductCategoriesViewModel();
  const FindManyProductTopicsReq = useFindManyProductTopicsViewModel();
  const FindManyProductsReq = useFindAllProducts();

  const [pageUrlState, setPageUrlState] = useUrlState<TPageUrlState>({
    id: undefined,
    action: undefined,
  });

  const methodFilterProductLines = useForm<FindPaginationProductLinePayload>({
    defaultValues: {
      pagination: {
        page: 1,
        limit: 20,
      },
      filter: {
        name: undefined,
        isActive: undefined,
        productCategoryId: undefined,
        productTopicIds: undefined,
      },
    },
  });
  const filterProductLinesValues = methodFilterProductLines.watch();

  useEffect(() => {
    FindPaginationProductLinesReq.run(filterProductLinesValues);
    FindManyProductCategoriesReq.run({
      search: '',
      isActive: undefined,
    });
    FindManyProductTopicsReq.run({
      search: '',
      isActive: undefined,
    });
    FindManyProductsReq.run({
      search: '',
      isActive: undefined,
    });
  }, []);

  useEffect(() => {
    FindPaginationProductLinesReq.run(filterProductLinesValues);
  }, [JSON.stringify(filterProductLinesValues)]);

  const data: TContextValues = {
    productLinesFindPaginationAction: pick(FindPaginationProductLinesReq, [
      'data',
      'loading',
      'runAsync',
      'refresh',
    ]),
    productCategoriesFindAllAction: pick(FindManyProductCategoriesReq, [
      'data',
      'loading',
      'runAsync',
      'refresh',
    ]),
    productTopicsFindAllAction: pick(FindManyProductTopicsReq, [
      'data',
      'loading',
      'runAsync',
      'refresh',
    ]),
    productsFindAllAction: pick(FindManyProductsReq, ['data', 'loading', 'runAsync', 'refresh']),
    methodFilterProductLines,
    resetPage: () => methodFilterProductLines.setValue('pagination.page', 1),
    pageUrlState,
    setPageUrlState,
  };

  return <MemoProvider {...data}>{children}</MemoProvider>;
};
