import { ProductCategory } from '@/domains/product-category/entities';
import {
  FindPaginationProductCategoryPayload,
  FindManyProductCategoryPayload,
  CreateProductCategoryPayload,
  UpdateProductCategoryPayload,
} from '@/domains/product-category/ports/payloads';
import { ProductCategoriesRepository } from '@/domains/product-category/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_PRODUCT_CATEGORIES } from '../../graphql';
import { FindResult } from '@/core/interfaces/findResult';

export default class ProductCategoriesRepoImpl implements ProductCategoriesRepository {
  findPagination(
    payload?: FindPaginationProductCategoryPayload,
  ): Promise<FindResult<ProductCategory>> {
    throw new Error('Method not implemented.');
  }
  async findMany(payload?: FindManyProductCategoryPayload): Promise<ProductCategory[]> {
    const result = await HttpClientAdapter.query<ProductCategory[]>({
      query: GET_PRODUCT_CATEGORIES,
      dataKey: 'ProductCategory_find',
      variables: { query: { ...payload } },
    });
    return result;
  }
  create(payload: CreateProductCategoryPayload): Promise<ProductCategory> {
    throw new Error('Method not implemented.');
  }
  update(id: string, payload: UpdateProductCategoryPayload): Promise<ProductCategory> {
    throw new Error('Method not implemented.');
  }
}
