import { UpdateProductLinePayload } from '../ports/payloads';
import { ProductLinesRepository } from '../ports/repositories';

export class UpdateProductLineUseCase {
  constructor(private readonly repo: ProductLinesRepository) {}

  async update(payload: UpdateProductLinePayload) {
    return await this.repo.update(payload.id, payload);
  }
}
