import { FindAllProductsResponse, QueryProductsPayload } from '@/domains/products/ports/payloads';
import { FindAllProductsUseCase } from '@/domains/products/usecases/find-all.usecase';
import ProductRepoImplement from '../adapters/repositories/products.repo';
import { useRequest } from 'ahooks';

export default function useFindAllProducts() {
  const usecase = new FindAllProductsUseCase(new ProductRepoImplement());

  const actionGetAllProducts = async (payload?: QueryProductsPayload) => {
    try {
      const data = await usecase.run(payload);
      return data;
    } catch (error) {
      return { data: [], count: 0, limit: 20, page: 1, pages: 1 } as FindAllProductsResponse;
    }
  };
  const getAllProductsReq = useRequest(actionGetAllProducts, { manual: true, retryCount: 2 });

  return getAllProductsReq;
}
