import { gql } from '@apollo/client';

export const GET_COURSE_TOPICS = gql`
  query CourseTopic_find($input: FindCourseTopicInput) {
    CourseTopic_find(input: $input) {
      id
      name
      description
      isActive
    }
  }
`;
