import { useRequest } from 'ahooks';
import ProductLineRepoImplement from '../adapters/repositories/product-lines.repo';
import { FindProductLinesUseCase } from '@/domains/product-line/usecases';
import { FindPaginationProductLinePayload } from '@/domains/product-line/ports/payloads';

export function useFindPaginationProductLinesViewModel(options?: any) {
  const usecase = new FindProductLinesUseCase(new ProductLineRepoImplement());

  const req = useRequest(
    async (payload: FindPaginationProductLinePayload) => {
      payload = {
        ...payload,
        filter: {
          ...payload.filter,
          productTopicIds: payload.filter.productTopicIds?.length
            ? payload.filter.productTopicIds
            : undefined,
          name: payload.filter.name?.length ? payload.filter.name : undefined,
        },
      };
      const result = await usecase.findPagination(payload);
      return result;
    },
    { manual: true, retryCount: 2, ...(options || {}) },
  );
  return req;
}
