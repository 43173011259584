// Generate code from clean architecture template

import { FindPaginationProductTopicPayload, FindManyProductTopicPayload } from '../ports/payloads';
import { ProductTopicsRepository } from '../ports/repositories';

export class FindProductTopicsUseCase {
  constructor(private readonly repo: ProductTopicsRepository) {}

  async findPagination(payload: FindPaginationProductTopicPayload) {
    return await this.repo.findPagination(payload);
  }

  async findMany(payload: FindManyProductTopicPayload) {
    return await this.repo.findMany(payload);
  }
}
