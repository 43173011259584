import { gql } from '@apollo/client';
import { ProductData } from './product-data';

export const GET_ALL_PRODUCTS = gql`
  query Product_find($query: QueryProductInput) {
    Product_find(query: $query) {
      data {
        ${ProductData}
      }
      count
      limit
      page
      pages
    }
  }
`;
