// Generate code from clean architecture template

import { FindOneProductLinePayload, FindPaginationProductLinePayload } from '../ports/payloads';
import { ProductLinesRepository } from '../ports/repositories';

export class FindProductLinesUseCase {
  constructor(private readonly repo: ProductLinesRepository) {}

  async findPagination(payload: FindPaginationProductLinePayload) {
    return await this.repo.findPagination(payload);
  }

  async findOne(payload: FindOneProductLinePayload) {
    return await this.repo.findOne(payload);
  }
}
