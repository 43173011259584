/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { EditOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import { useProductLineContext } from '../../context';
import { ProductLineEntity } from '@/domains/product-line/entities';
import { useOpacity } from '@/core';
import CoreSelect from '@/components/Form/SelectAntForm';
import { useTranslation } from 'react-i18next';

export const ProductLineColumns = () => {
  const { t } = useTranslation();
  const { opacity, setOpacity } = useOpacity();
  const { handleClickEdit } = useHandleActionItem();
  const {
    methodFilterProductLines,
    productCategoriesFindAllAction,
    productTopicsFindAllAction,
    productsFindAllAction,
    resetPage,
  } = useProductLineContext();
  const { setValue } = methodFilterProductLines;
  const { data: productCategories } = productCategoriesFindAllAction;
  const { data: productTopics } = productTopicsFindAllAction;
  const { data: products } = productsFindAllAction;

  const tableColumns: ColumnsType<ProductLineEntity> = [
    {
      title: t('common.productLinesName'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
        return (
          <Input
            autoFocus
            placeholder={t('common.search')}
            value={selectedKeys[0] as string}
            onPressEnter={(e) => {
              setValue('filter.name', get(e.target, 'value', undefined));
              confirm();
            }}
            onBlur={(e) => {
              setValue('filter.name', get(e.target, 'value', undefined));
              confirm();
            }}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
          />
        );
      },
    },
    {
      title: t('common.productLinesProductCategory'),
      dataIndex: 'productCategoryId',
      width: 200,
      key: 'productCategoryId',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <CoreSelect
          control={methodFilterProductLines.control}
          name='filter.productCategoryId'
          placeholder={t('common.productLinesProductCategory')}
          value={selectedKeys[0] as string}
          optionsProps={
            productCategories?.map((item: any) => ({ label: item.name, value: item.id })) || []
          }
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            setValue('filter.productCategoryId', value);
            confirm();
          }}
        />
      ),
      render: (value: string) => {
        const productCategory = productCategories?.find((item) => item.id === value);
        return <span className='text-start'>{productCategory?.name}</span>;
      },
    },

    {
      title: t('common.productLinesProductTopics'),
      dataIndex: 'productTopicIds',
      key: 'productTopicIds',
      ellipsis: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <CoreSelect
          control={methodFilterProductLines.control}
          name='filter.productTopicIds'
          placeholder={t('common.productLinesProductTopics')}
          optionsProps={
            productTopics?.map((item: any) => ({ label: item.name, value: item.id })) || []
          }
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            setValue('filter.productTopicIds', value);
            resetPage();
            confirm();
          }}
        />
      ),
      render: (value: string[]) => (
        <div className='flex flex-wrap gap-1'>
          {value?.map((item) => {
            const productTopic = productTopics?.find((topic) => topic.id === item);
            return (
              <Tag key={productTopic?.id} className='text-center !m-0'>
                {productTopic?.name}
              </Tag>
            );
          })}
        </div>
      ),
    },

    {
      title: t('common.productLinesStatus'),
      width: 150,
      dataIndex: 'isActive',
      key: 'isActive',
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Deactivated',
          value: false,
        },
      ],
      render: (value: boolean) => {
        return value === true ? (
          <Tag className='text-center' color='green'>
            {t('common.active')}
          </Tag>
        ) : (
          <Tag className='text-center'>{t('common.inactive')}</Tag>
        );
      },
    },

    {
      title: '',
      width: 100,
      key: 'action',
      render: (record) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            <EditOutlined
              style={{ fontSize: '18px', color: 'blue', cursor: 'pointer', opacity }}
              onClick={() => handleClickEdit(record)}
            />
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
