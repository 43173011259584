import { FindAllProductsResponse, QueryProductsPayload } from '@/domains/products/ports/payloads';
import { ProductsRepository } from '@/domains/products/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_ALL_PRODUCTS } from '../../graphql';

export default class ProductRepoImplement implements ProductsRepository {
  async findPaginate(payload?: QueryProductsPayload): Promise<FindAllProductsResponse> {
    const response = await HttpClientAdapter.query<FindAllProductsResponse>({
      query: GET_ALL_PRODUCTS,
      dataKey: 'Product_find',
      variables: { query: { page: 1, limit: 1000, ...payload } },
    });
    return response;
  }
}
