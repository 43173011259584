import { ProductTopic } from '@/domains/product-topic/entities';
import {
  FindPaginationProductTopicPayload,
  FindManyProductTopicPayload,
  CreateProductTopicPayload,
  UpdateProductTopicPayload,
} from '@/domains/product-topic/ports/payloads';
import { ProductTopicsRepository } from '@/domains/product-topic/ports/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_COURSE_TOPICS } from '../../graphql';
import { FindResult } from '@/core/interfaces/findResult';

export default class ProductTopicsRepoImplement implements ProductTopicsRepository {
  findPagination(payload: FindPaginationProductTopicPayload): Promise<FindResult<ProductTopic>> {
    throw new Error('Method not implemented.');
  }
  async findMany(payload: FindManyProductTopicPayload): Promise<ProductTopic[]> {
    const response = await HttpClientAdapter.query<ProductTopic[]>({
      query: GET_COURSE_TOPICS,
      dataKey: 'CourseTopic_find',
      variables: { input: { ...payload } },
    });
    return response;
  }
  create(payload: CreateProductTopicPayload): Promise<ProductTopic> {
    throw new Error('Method not implemented.');
  }
  update(id: string, payload: UpdateProductTopicPayload): Promise<ProductTopic> {
    throw new Error('Method not implemented.');
  }
}
