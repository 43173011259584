import { Typography } from 'antd';
import React from 'react';

type TLabelProps = { label?: React.ReactNode; required?: boolean; className?: string };

const Label = ({ label, required, className }: TLabelProps) => {
  if (!label) return <></>;
  return (
    <Typography.Text className={`${className} label-container`}>
      {label} {required ? <span className='text-[#F5222D]'>*</span> : ''}
    </Typography.Text>
  );
};

export default Label;
